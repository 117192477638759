import React from 'react';
import Cookies from 'js-cookie';
import Link from 'next/link';

import Logo from 'components/ui/Logo';
import { FooterMenuItem } from 'types/Menu';
import styles from './Footer.module.css';

export interface FooterProps {
  links: FooterMenuItem[];
}

const Footer: React.FC<FooterProps> = ({ links }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoCont}>
        <Link href="/" passHref>
          <a>
            <Logo orientation="vertical" className={styles.logo} variant="full" />
          </a>
        </Link>
      </div>
      <div className={styles.links}>
        {links.map(link => (
          <Link passHref href={link.href} key={link.text}>
            <a className={styles.link}>{link.text}</a>
          </Link>
        ))}
        <a
          onClick={() => {
            if (window.acceptCookieTokenName != null) {
              Cookies.remove(window.acceptCookieTokenName);
            }
            window.location.reload();
          }}
          className={styles.link}
        >
          Hantera cookies
        </a>
      </div>
      <a className={styles.mailLink} href="mailto:kundservice@arkivet.com">
        kundservice@arkivet.com
      </a>
    </footer>
  );
};

export default Footer;
