import React, { useContext, useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { useUrlId } from 'hooks';
import useBananasCommerce from 'hooks/useBananasCommerce';
import useLocalStorage from 'hooks/useLocalStorage';
import { useCartVerification } from 'queries';
import { CartProduct } from 'types/Product';
import { useUser } from './UserContext';

export type CartContext = {
  addItem: (product: CartProduct) => void;
  addItemWithVerify: (product: CartProduct) => Promise<void>;
  cartDrawerShown: [boolean, (v: boolean) => void];
  clearCart: () => void;
  isInCart: (reference: string | CartProduct) => boolean;
  products: CartProduct[];
  removeItem: (reference: string | CartProduct) => void;
};

const CartContext = React.createContext<CartContext>({} as CartContext);

export const useCart = () => useContext(CartContext);

export const CartContextProvider = ({
  children,
  ...restProps
}: Omit<React.ComponentProps<typeof CartContext.Provider>, 'value'>) => {
  const [applyIdCart, removeIdCart] = useUrlId('cart');
  const router = useRouter();
  const user = useUser();
  const bcom = useBananasCommerce();

  const previousProducts = useRef<null | CartProduct[]>(null);
  const [products, setProducts] = useLocalStorage<CartProduct[]>('cart-v1.1', []);

  const [cartDate, setCartDate] = useLocalStorage('cartDate', new Date().toISOString());

  const queryClient = useQueryClient();

  useEffect(() => {
    if (dayjs(cartDate).isBefore(dayjs().subtract(3, 'day'))) {
      setProducts([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartDate]);

  useEffect(() => {
    setCartDate(new Date().toISOString());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useQuery(
    [
      `product-track-loop-${products
        .map(v => v.item.reference)
        .sort()
        .join('-')}`,
    ],
    async () => {
      if (
        previousProducts.current !== null &&
        JSON.stringify(previousProducts.current) !== JSON.stringify(products)
      ) {
        if (products.length !== 0) {
          bcom.arkivet.track({
            cartKey: user.id,
            items: products.map(v => ({
              quantity: 1,
              reference: v.item.reference,
            })),
          });
        } else {
          bcom.arkivet.untrack({ cartKey: user.id });
        }
      }

      previousProducts.current = JSON.parse(JSON.stringify(products));

      return "some data that isn't undefined";
    },
    {
      cacheTime: 0,
      refetchInterval: 60 * 1000 * 9,
      refetchOnWindowFocus: false,
    },
  );

  const isInCart = React.useCallback(
    (reference: string | CartProduct): boolean => {
      if (typeof reference !== 'string') {
        return isInCart(reference.item.reference);
      }

      return products.some(data => data.item.reference === reference);
    },
    [products],
  );

  const [cartDrawerShown, setCartDrawerShown] = React.useState(/#cart$/.test(router.asPath));

  const addItem = React.useCallback(
    (product: CartProduct) => {
      if (!isInCart(product.item.reference)) {
        setProducts(prev => [...prev, product]);
      }
    },
    [setProducts, isInCart],
  );

  /**
   * Adds an item to the cart and validates with bcom.cart
   */
  const addItemWithVerify = React.useCallback(
    async (product: CartProduct) => {
      const keyOpts = {
        references: products.map(v => v.item.reference).concat(product.item.reference),
      };

      // Fetch data will throw if the cart is invalid
      const data = await useCartVerification.fetchData(keyOpts);

      const cartErrorMessage = Object.values(data).find(v => v.reference === product.item.reference)
        ?.error;
      if (cartErrorMessage) {
        throw new Error(cartErrorMessage);
      }

      // Update cart
      queryClient.setQueryData(useCartVerification.queryKey(keyOpts), data);
      addItem(product);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addItem, user],
  );

  const removeItem = React.useCallback(
    (reference: string | CartProduct): void => {
      if (typeof reference !== 'string') {
        return removeItem(reference.item.reference);
      }

      setProducts(prev => prev.filter(data => data.item.reference !== reference));
    },
    [setProducts],
  );

  const clearCart = React.useCallback(() => {
    setProducts([]);
  }, [setProducts]);

  const value: CartContext = {
    addItem,
    addItemWithVerify,
    cartDrawerShown: [
      cartDrawerShown,
      (v: boolean) => {
        if (v) {
          applyIdCart();
          setCartDrawerShown(true);
        } else {
          removeIdCart();
          setCartDrawerShown(false);
        }
      },
    ],
    clearCart,
    isInCart,
    products,
    removeItem,
  };

  return (
    <CartContext.Provider value={value} {...restProps}>
      {children}
    </CartContext.Provider>
  );
};
