import getPreviousRelease from './next/getProductFeed';
import getBrand from './sanity/brand/getBrandBySlug';
import getBrandBySlug from './sanity/brand/getBrandBySlug';
import getBrands from './sanity/brand/getBrands';
import { sanityClient } from './sanity/client';
import getCollections from './sanity/collection/getCollections';
import getFAQ from './sanity/faq/getFAQ';
import { getProductFeed } from './sanity/getProductFeed';
import getFooterMenu from './sanity/menu/getFooterMenu';
import getNavbarMenu from './sanity/menu/getNavbarMenu';
import getAllPages from './sanity/pages/getAllPages';
import getStaticPage from './sanity/pages/getStaticPage';
import getBrandsProducts from './sanity/products/getBrandsProducts';
import getCollectionProducts from './sanity/products/getCollectionElements';
import getProduct from './sanity/products/getProduct';
import getNextScheduledReleases from './sanity/scheduled-releases/getNextScheduledReleases';
import getPreviousScheduledRelease from './sanity/scheduled-releases/getPreviousScheduledRelease';
import getPreviousScheduledReleases from './sanity/scheduled-releases/getPreviousScheduledReleases';
import getStaticContent from './sanity/static-content/getStaticContent';

function api() {
  return {
    next: {
      feed: getPreviousRelease,
    },
    sanity: {
      allPages: getAllPages,
      brand: getBrand,
      brandBySlug: getBrandBySlug,
      brands: getBrands,
      brandsProducts: getBrandsProducts,

      /** the client for the sanity api */
      client: sanityClient,

      collectionProducts: getCollectionProducts,

      collections: getCollections,

      faq: getFAQ,
      feed: getProductFeed,
      footerMenu: getFooterMenu,
      navbarMenu: getNavbarMenu,
      nextScheduledReleases: getNextScheduledReleases,
      previousScheduledRelease: getPreviousScheduledRelease,
      previousScheduledReleases: getPreviousScheduledReleases,
      product: getProduct,
      staticContent: getStaticContent,
      staticPage: getStaticPage,
    },
  };
}

export default api;
