import React, { ComponentProps, useMemo } from 'react';

import logoSrc from 'icons/full-logo.svg?url';
import logoHorizontalSrc from 'icons/full-logo-horizontal.svg?url';
import { cx } from 'utils';
import styles from './Logo.module.css';

type LogoType = 'full' | 'small';
type Orientation = 'vertical' | 'horizontal';

export type LogoProps = {
  orientation?: Orientation;
  variant: LogoType;
} & Omit<ComponentProps<'div'>, 'alt' | 'src'>;

const Logo: React.FC<LogoProps> = ({ className, orientation, variant, ...props }) => {
  const src = useMemo<string>(() => {
    // Currently variant isn't being used here, but i think it makes sense for it to be a dependency for this memo
    // This memo is more here for clarity rather than performance

    switch (orientation) {
      case 'horizontal':
        return logoHorizontalSrc;
      // Fallthrough for clarity
      case 'vertical':
      default:
        return logoSrc;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation, variant]);

  return (
    <div
      className={cx(styles.logo, className, variant === 'full' && styles.full)}
      aria-label="Arkivet logotyp"
      {...props}
    >
      <div style={{ backgroundImage: `url('${src}')` }} className={styles.image}></div>
    </div>
  );
};

export default Logo;
