import dayjs from 'dayjs';

/** Id of the portal for the modals */
export const MODAL_ROOT_ID = 'modal-root';

/**
 * These are links to pages that are just hard coded because
 * we didn't have time to develop a proper feature for them.
 * This object is just a way to store them somewhere centrally
 */
export const DANGLING_LINKS = {
  // This is a sanity dynamic page link, so this could change at any time
  // And there is no guarantee that this url will exist either
  // It just does currently
  HANDING_IN: '/sida/inlämning',
  INTEGRITY_POLICY: '/sida/integritetspolicy',
  TERMS_AND_CONDITIONS: '/sida/allmanna-villkor',
};

/** Currenty defined for the bcom sitecode, should in the future come from a bcom get config call */
export const CURRENCY = 'SEK';

/**
 * How long each page should be cached in seconds
 */
export const CACHE_TIMES = {
  BOOK_TIME: 600,
  BRAND: 600,
  BRANDS: 600,
  CANCEL_BOOKING: 600,
  FAQ: 600,
  HOME: 600,
  MY_PAGES: 600,
  /** CMS generated content page */
  PAGE: 600,
  PRODUCT: 600,
  RECEIPT: 600,
};

export const PRODUCTS_ARCHIVED_FEED = () => dayjs().add(-14, 'day').toISOString();
export const PRODUCTS_ARCHIVED_PAGE = () => dayjs().add(-1, 'month').toISOString();
export const PROMOTED_ARCHIVED = () => dayjs().add(-2, 'month').toISOString();
