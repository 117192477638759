import { FooterMenuItem } from 'types/Menu';
import { sanityClient } from '../client';
import { preProgrammedPages } from './preDefinedPages';

export default async function getFooterMenu() {
  try {
    const data = await sanityClient.fetch<Record<string, unknown>[]>(
      `*[_type == "footerMenu"] | order(orderRank asc) { text, typeOfPage, preProgrammedPage, "sanitySlug": page->slug.current }`,
    );

    return data
      .map((v): FooterMenuItem | null => {
        switch (v.typeOfPage) {
          case 'next-managed': {
            const { preProgrammedPage = 'NEVER' } = v as {
              preProgrammedPage: string | undefined;
            };

            if (!(preProgrammedPage in preProgrammedPages)) {
              return null;
            }

            return {
              href: preProgrammedPages[preProgrammedPage as keyof typeof preProgrammedPages],
              text: v.text as string,
            };
          }
          case 'sanity-managed':
            if (typeof v.sanitySlug !== 'string') {
              return null;
            }

            return {
              href: `/sida/${v.sanitySlug}`,
              text: v.text as string,
            };
          default:
            return null;
        }
      })
      .filter((v): v is FooterMenuItem => v !== null);
  } catch {
    return [];
  }
}
