import React, { useEffect } from 'react';

import WelcomeBanner from 'components/sections/WelcomeBanner';
import { useCart } from 'contexts/CartContext';
import { useSideMenuShown } from 'contexts/SideMenu';
import { NavbarMenuItem } from 'types/Menu';
import Header from './Header';

export type NavbarProps = {
  color: 'white' | 'secondary';
  items: NavbarMenuItem[];
  nextRelease?: Date;
  showWelcomeBanner?: boolean;
};

const Navbar: React.FC<NavbarProps> = props => {
  const {
    cartDrawerShown: [, setCheckoutOpen],
    products,
  } = useCart();

  const [, setSideMenuShown] = useSideMenuShown();

  useEffect(() => {
    const handler = () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}`);
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  });

  return (
    <>
      {props.showWelcomeBanner && props.nextRelease && <WelcomeBanner date={props.nextRelease} />}
      <Header
        items={props.items}
        onMenuClick={() => setSideMenuShown(true)}
        onShoppingCartClick={() => setCheckoutOpen(true)}
        amountOfCartItems={products.length}
        color={props.color}
      />
    </>
  );
};

export default Navbar;
